import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AGGridWrapper } from "../../../common/AGGridWrapper/AGGridWrapper";
import { convertToTitleCase, updateSvgDimension } from "../../../helpers";
import { StatusCell } from "../CellRenderer/StatusCell";
import { ICellRendererParams } from "ag-grid-community";
import { Tag } from "../../../common/Tag/Tag";
import { IconCell } from "../CellRenderer/IconCell";
import { DataBundleLayersIcon } from "../../../common/Icons/DataBundleLayersIcon";
import { OfficeBuildingIcon } from "../../../common/Icons/OfficeBuildingIcon";
import { Partner } from "../../../common/Icons/Partner";
import { SourceOutput } from "../../../generated";
import { getSource } from "../../sourceIcons";
import { CardHolder } from "./style";

export const CardView = ({
  setDetail,
  rowData,
}: {
  setDetail: Function;
  rowData: SourceOutput[];
}) => {
  const { palette } = useTheme();

  return (
    <CardHolder className="ag-theme-material">
      {rowData.map((row) => (
        <Box className="cardholder">
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              minWidth: "100%",
            }}
            onClick={() => setDetail(row)}
            className="project-card"
            tabIndex={1}
            aria-labelledby={`${row.name}-title`}
            role="link"
          >
            <Card
              sx={{
                cursor: "pointer",
                color: palette.gray.darkest,
                fontSize: "14px",
                lineHeight: "20px",
                flex: 1,

                "&:hover": {
                  background: `linear-gradient(0deg, ${palette.primary.lighter}, ${palette.primary.lighter}), ${palette.common.white}`,
                },

                ".csb-top-wrap": {
                  flexGrow: "1",
                },

                ".csb-icn": {
                  display: "block",
                  marginBottom: "12px",
                },

                ".csb-status-tag": {
                  fontWeight: "500",
                  padding: "2px 8px",
                  marginBottom: "16px",
                },

                ".csb-description": {
                  margin: "12px 0",
                },

                ".csb-ptner-icn": {
                  width: "12px",
                  height: "12px",
                  color: palette.grayAccent.light,
                },
              }}
            >
              <Box
                sx={{
                  padding: "20px 16px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="csb-top-wrap">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0 0 1rem",
                      color: palette.grayAccent.light,
                      svg: {
                        width: "24px",
                        height: "24px",
                        margin: "0 !important",
                        verticalAlign: "top",
                      },
                      ".source-holder": {
                        display: "block",
                      },

                      ".source-logo": {
                        display: "block",
                        width: "24px",
                        height: "24px",

                        svg: {
                          width: "24px",
                          height: "24px",
                          verticalAlign: "top",
                        },

                        img: {
                          width: "24px",
                          height: "24px",
                          verticalAlign: "top",
                        },
                      },
                    }}
                  >
                    {row?.logoUrl.includes("<svg") ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: updateSvgDimension(row?.logoUrl),
                        }}
                      />
                    ) : (
                      <div className="source-logo">
                        <img src={row?.logoUrl} width="24" height="24" />
                      </div>
                    )}

                    <Tooltip
                      title={convertToTitleCase(row?.sourceType)}
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: palette.common.black,
                            textTransform: "capitalize",
                          },
                        },
                        arrow: { sx: { color: palette.common.black } },
                      }}
                      arrow
                    >
                      <span className="source-holder">
                        {getSource(row?.sourceType)}
                      </span>
                    </Tooltip>
                  </Box>
                  <Typography
                    variant="body3"
                    sx={{
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "24px",
                      display: "block",
                      marginBottom: "12px",
                    }}
                  >
                    {row.name}
                  </Typography>
                  <StatusCell value={row.status} />
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "10px",
                      marginBottom: "8px",
                      justifyContent: "space-between",

                      ".csb-data-heading": {
                        flexGrow: "1",
                        color: palette.common.black,
                        margin: "0",
                        fontWeight: "500",
                      },

                      ".csb-data-subheading": {
                        flexShrink: "0",
                        color: palette.common.black,
                        margin: "0",
                        textAlign: "right",
                      },

                      ".csb-data-icn": {
                        color: palette.grayAccent.light,
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                        verticalAlign: "-1px",
                      },
                    }}
                  >
                    <p className="csb-data-heading">
                      <DataBundleLayersIcon className="csb-data-icn" />
                      <span>Data Asset:</span>
                    </p>
                    <p className="csb-data-subheading">{row.dataAssets}</p>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "10px",
                      justifyContent: "space-between",

                      ".csb-data-heading": {
                        flexGrow: "1",
                        maxWidth: "calc(70% - 5px)",
                        color: palette.common.black,
                        margin: "0",
                        fontWeight: "500",
                      },

                      ".csb-data-subheading": {
                        flexShrink: "0",
                        color: palette.common.black,
                        margin: "0",
                      },

                      ".csb-data-icn": {
                        color: palette.grayAccent.light,
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                        verticalAlign: "-1px",
                      },
                    }}
                  >
                    <p className="csb-data-heading">
                      <OfficeBuildingIcon className="csb-data-icn" />
                      <span>Owner:</span>
                    </p>
                    <p className="csb-data-subheading">{row.owner.name}</p>
                  </Box>
                  <p className="csb-description">{row.description}</p>
                </div>
                {row?.tags && row?.tags?.length > 0 && (
                  <div className="csb-bottom-wrap">
                    <Divider sx={{ mb: 4 }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",

                        ".csb-tagline > div": {
                          padding: "1px 4px !important",
                          borderRadius: "4px !important",
                          background: `${palette.settings.lighter} !important`,
                        },

                        ".csb-tagline p": {
                          fontWeight: "500",
                          fontSize: "12px !important",
                          lineHeight: "18px !important",
                        },
                      }}
                    >
                      {row.tags.map((tag: string) => (
                        <Tag
                          className="csb-tagline"
                          label={tag}
                          dismissable={false}
                        />
                      ))}
                    </Box>
                  </div>
                )}
              </Box>
            </Card>
          </Box>
        </Box>
      ))}
    </CardHolder>
  );
};
